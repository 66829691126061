import React, { useEffect } from "react";

import bounce from "../script/bounce";

export default function Home() {

  useEffect(() => {

    document.title =
      "Adem Kurşun | Full Stack Developer";

    bounce();

    const s = document.querySelector(".Slast");

    s.style.opacity = 1;
    s.style.transform = "rotate(360deg)";

    return () => {
      s.style.opacity = 0;
      s.style.transform = "rotate(0deg)";
    };
  }, []);

  return (
    <>

      <div className="container home-page">
        <span className="tags top-tags"> &nbsp;&nbsp;&nbsp;&lt;body&gt;</span>
        <div className="text-zone">
          <h1
            aria-label=" Hi, I’m Fishuke,web developer."
            className="blast-root"
          >
            <span className="blast">H</span>
            <span className="blast">i</span>
            <span className="blast">!</span>
            <span className="blast" style={{fontSize : '48px', lineHeight : '48px', verticalAlign : 'top'}}>👋</span>
            <br />
            <span className="blast">I</span>
            <span className="blast">’</span>
            <span className="blast">m&nbsp;</span>
            <span className="Slast">F</span>
            <span className="blast">i</span>
            <span className="blast">s</span>
            <span className="blast">h</span>
            <span className="blast">u</span>
            <span className="blast">k</span>
            <span className="blast">e</span>
            <span className="blast">,</span>
            <br />
            <span className="blast">w</span>
            <span className="blast">e</span>
            <span className="blast">b&nbsp;</span>
            <span className="blast">d</span>
            <span className="blast">e</span>
            <span className="blast">v</span>
            <span className="blast">e</span>
            <span className="blast">l</span>
            <span className="blast">o</span>
            <span className="blast">p</span>
            <span className="blast">e</span>
            <span className="blast">r</span>
            <span className="blast">.</span>
          </h1>
          <h2>Full Stack Developer / Freelancer</h2>
          <div className="icons">
            <a href="https://github.com/fishuke">
              <i className="fab fa-github fa-3x"></i>
            </a>
            <a href="https://www.linkedin.com/in/fishuke/">
              <i className="fab fa-linkedin fa-3x"></i>
            </a>
            <a href="https://www.youtube.com/fishuke/">
              <i className="fab fa-youtube fa-3x"></i>
            </a>
            <a href="https://instagram.com/f1shuke">
              <i className="fab fa-instagram fa-3x"></i>
            </a>
          </div>
        </div>

        <span className="tags bottom-tags">
          &nbsp;&nbsp;&nbsp;&lt;/body&gt;
          <br />
          &lt;/html&gt;
        </span>
      </div>
    </>
  );
}
