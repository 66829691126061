import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.scss";

import Nav from "./components/Nav";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Contact from "./components/Contact";
import {default as render} from "./script/gravity-points";

export default function App() {

  useEffect(() => {


    render();

  }, []);

  return (
    <Router>
      <Nav />
      <canvas id="c"></canvas>
      <Switch>

        <Route path={process.env.PUBLIC_URL + "/"} exact>
          <Home />
        </Route>
        <Route path={process.env.PUBLIC_URL + "/about"} exact>
          <About />
        </Route>
        <Route path={process.env.PUBLIC_URL + "/skills"} exact>
          <Skills />
        </Route>
        {/*<Route path={process.env.PUBLIC_URL + "/work"} exact>*/}
        {/*  <Work />*/}
        {/*</Route>*/}
        <Route path={process.env.PUBLIC_URL + "/contact"} exact>
          <Contact />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}
