import React from "react";
import { NavLink } from "react-router-dom";

export default function Nav() {
  return (
    <>
      <div id="nav-bar">
        <a href="https://fishuke.me">
          <img
            className="logo"
            src="img/profile.png"
            alt="logo"
          />
        </a>

        <nav>
          <NavLink
            to={process.env.PUBLIC_URL + "/"}
            className="link"
            activeClassName="active-nav"
            exact
          >
            <i className="fas fa-home fa-2x"></i>
            <div className="overlay">
              <div className="text">HOME</div>
            </div>
          </NavLink>
          <NavLink
            to={process.env.PUBLIC_URL + "/about"}
            className="link"
            activeClassName="active-nav"
          >
            <i className="image fas fa-user fa-2x"></i>
            <div className="overlay">
              <div className="text">ABOUT</div>
            </div>
          </NavLink>
          <NavLink
            to={process.env.PUBLIC_URL + "/skills"}
            className="link"
            activeClassName="active-nav"
          >
            <i className="fas fa-code fa-2x"></i>
            <div className="overlay">
              <div className="text">SKILLS</div>
            </div>
          </NavLink>
          {/*<NavLink*/}
          {/*  to={process.env.PUBLIC_URL + "/work"}*/}
          {/*  className="link"*/}
          {/*  activeClassName="active-nav"*/}
          {/*>*/}
          {/*  <i className="fas fa-eye fa-2x"></i>*/}
          {/*  <div className="overlay">*/}
          {/*    <div className="text">MY WORK</div>*/}
          {/*  </div>*/}
          {/*</NavLink>*/}
          <NavLink
            to={process.env.PUBLIC_URL + "/contact"}
            className="link"
            activeClassName="active-nav"
          >
            <i className="fas fa-envelope fa-2x"></i>
            <div className="overlay">
              <div className="text">CONTACT</div>
            </div>
          </NavLink>
        </nav>
        <ul>
          <li>
            <a
                href="https://www.github.com/fishuke/"
                target="_blank"
                rel="noopener noreferrer"
            >
              <i className="fab fa-github"></i>
            </a>
          </li>
          <li>
            <a
                href="https://www.linkedin.com/in/fishuke/"
                target="_blank"
                rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a
                href="https://www.youtube.com/fishuke/"
                target="_blank"
                rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </li>
          <li>
            <a
                href="https://www.instagram.com/f1shuke/"
                target="_blank"
                rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
