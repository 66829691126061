import React, { useEffect } from "react";
import { motion } from "framer-motion";
import bounce from "../script/bounce";

export default function Contact() {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.title = "Adem Kurşun | Contact Me";

    bounce();

  }, []);

  function handleSubmit(e) {
    alert("Your Message is sent!");
  }

  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 40,
  };

  return (
    <>
      <div className="container home-page">
        <span className="tags top-tags"> &nbs p;&nbsp;&nbsp;&lt;body&gt;</span>
        <div className="text-zone">
          <h1
            aria-label=" Hi, I’m Fishuke,web developer."
            className="blast-root"
          >
            <span className="blast" style={{ color: "#23ffde" }}>
              C
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              o
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              n
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              t
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              a
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              c
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              t&nbsp;
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              m
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              e
            </span>
          </h1>
          <p>
            I am interested in freelance opportunities – especially ambitious or
            large projects. However, if you have other request or question,
            don’t hesitate to contact me using below either.
          </p>
          <div className="icons">
            <a href="https://www.linkedin.com/in/fishuke/">
              <i className="fab fa-linkedin fa-3x"></i>
            </a>
            <a href="https://instagram.com/f1shuke">
              <i className="fab fa-instagram fa-3x"></i>
            </a>
            <a href="mailto:fishukee@gmail.com">
              <i className="fas fa-envelope fa-3x"></i>
            </a>
          </div>
        </div>

        <span className="tags bottom-tags">
          &nbsp;&nbsp;&nbsp;&lt;/body&gt;
          <br />
          &lt;/html&gt;
        </span>
      </div>

    </>
  );
}
