import React, {useEffect} from "react";
import bounce from "../script/bounce";
import Lottie from "react-lottie";
import animationData from "../lottie/developer.json";

export default function About() {
    useEffect(() => {
        document.title = "Adem Kurşun | About Me ";

        bounce();

    }, []);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <div className="container home-page">
                <span className="tags top-tags"> &nbsp;&nbsp;&nbsp;&lt;body&gt;</span>
                <div className="text-zone">
                    <h1
                        aria-label=" Hi, I’m Fishuke,web developer."
                        className="blast-root"
                    >
            <span className="blast" style={{color: "#23ffde"}}>
              A
            </span>
                        <span className="blast" style={{color: "#23ffde"}}>
              b
            </span>
                        <span className="blast" style={{color: "#23ffde"}}>
              o
            </span>
                        <span className="blast" style={{color: "#23ffde"}}>
              u
            </span>
                        <span className="blast" style={{color: "#23ffde"}}>
              t&nbsp;
            </span>
                        <span className="blast" style={{color: "#23ffde"}}>
              m
            </span>
                        <span className="blast" style={{color: "#23ffde"}}>
              e
            </span>
                    </h1>
                    <p align="LEFT">
                        A skilled and knowledgeable high school student with extensive
                        knowledge of <strong>programming</strong> and&nbsp;
                        <strong>web development</strong>. 👨‍💻
                        <br/>
                        <br/>
                        Well-organised enthusiastic person, problem solver, quick learner,
                        debugger and,
                        <br/>
                        a fan of "Samurai Jack", Embeded Systems and Music lover...
                        <br/>
                        <br/>
                        Interested in the development new apps and actively looking for new projects.
                        <br/>
                        <br/>
                        Follow me on&nbsp;
                        <a
                            href="https://www.instagram.com/f1shuke/"
                            style={{color: "#23ffde"}}
                        >
                            Instagram&nbsp;
                        </a>
                        to know more about me.
                    </p>
                </div>
                <span className="tags bottom-tags" style={{bottom: "10px"}}>
          &nbsp;&nbsp;&nbsp;&lt;/body&gt;
                    <br/>
                    &lt;/html&gt;
        </span>
            </div>
            <div id="my-lottie">
                <Lottie isClickToPauseDisabled={true} options={defaultOptions}
                        height={400}
                        width={400}/>
            </div>
        </>
    );
}
