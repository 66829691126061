import React, { useEffect } from "react";
import bounce from "../script/bounce";
import Lottie from 'react-lottie';
import animationData from '../lottie/developer.json'

export default function Skills() {
  useEffect(() => {
    document.title = "Adem Kurşun | Skills ";

    bounce();
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
      <div className="container home-page">
        <span className="tags top-tags"> &nbsp;&nbsp;&nbsp;&lt;body&gt;</span>
        <div className="text-zone">
          <h1
            aria-label=" Hi, I’m Fishuke,web developer."
            className="blast-root"
          >
            <span className="blast" style={{ color: "#23ffde" }}>
              Skills&nbsp;
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              &&nbsp;
            </span>
            <span className="blast" style={{ color: "#23ffde" }}>
              Experience
            </span>
          </h1>
          <p align="LEFT">
            My main area is web development with Angular and Nestjs.
            <br />
            <br />
            Languages I use: Py, Js(Nodejs), Sql, Bf, Css, C ++, Php, mostly Html, Sass and Ts.
            <br />
            Frameworks I use: Angular and Nestjs.
            <br />
            <br />
            I am also interested in embedded systems, i sometimes build something with Arduino and RaspberryPi.
            <br />
            Visit my&nbsp;
            <a
              href="https://www.github.com/fishuke/"
              style={{ color: "#23ffde" }}
            >
              Github&nbsp;
            </a>
            profile for more...
          </p>
        </div>

        <span className="tags bottom-tags" style={{ bottom: "10px" }}>
          &nbsp;&nbsp;&nbsp;&lt;/body&gt;
          <br />
          &lt;/html&gt;
        </span>
      </div>
      <div id="my-lottie">
        <Lottie isClickToPauseDisabled={true} options={defaultOptions}
                height={400}
                width={400}/>
      </div>
    </>
  );
}
